import React, { useState } from "react";
import {
  ChakraProvider,
  Box,
  FormControl,
  Input,
  Button,
  Center,
  Icon,
  useToast,
  Heading,
  Flex,
  Text,
  Image,
  InputGroup,
  InputRightElement,
  IconButton,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormLabel,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../../Redux/AuthReducer/Action";
import BgImg from "../Images/BgImg.png";
import Logo from "../Images/logo-img.png";
import Bg from "../Images/bg.png";
import Particle from "../Login/Particle";

const Login = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showPassword, setShowPassword] = useState(false);
  const [employeeid, setEmployeeid] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false); //Loader State
  const [otp, setotp] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const user = {
      employeeID: employeeid,
      password: password,
      otp: otp, // Include the new input field value
    };
    setIsLoading(true); // Show loader
    dispatch(signin(user, toast, navigate)).finally(() => setIsLoading(false)); // Hide loader
  };

  return (
    <Box
      position="relative"
      width="100%"
      height="100vh"
      margin="auto"
      backgroundColor="#fff"
      backgroundImage={`url(${Bg})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      border="2px solid #f3f3f3"
      backgroundPosition="50% 50%"
    >
      <Particle />
      {isLoading && ( // Display loader overlay
        <Flex
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(255, 255, 255, 0.8)"
          justifyContent="center"
          alignItems="center"
          zIndex="10"
        >
          <Spinner size="xl" color="red.500" thickness="4px" />
        </Flex>
      )}
      <Flex
        ml={260}
        mr={260}
        mt={90}
        height="410px"
        zIndex={1}
        position="relative"
      >
        <Box
          width="400px"
          height="430px"
          backgroundImage={`url(${BgImg})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          borderTopLeftRadius={12}
          borderBottomLeftRadius={12}
          boxShadow="rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
        >
          <Box margin="auto" mt="180px" height="50px">
            <Heading
              fontSize={18}
              color="#fff"
              letterSpacing="0.5px"
              fontFamily="Sans-serif"
              mt={0}
              mb="0.5rem"
              fontWeight={700}
              lineHeight={1.2}
            >
              UNLOCK YOUR BUSINESS POTENTIAL
              <Heading
                color="#fff"
                fontSize="1rem"
                mt={0}
                mb=".5rem"
                fontWeight={500}
                lineHeight={1.2}
              >
                Simplify, Streamline, Succeed!
              </Heading>
            </Heading>
          </Box>
        </Box>
        <Box
          width="420px"
          justifyContent="center"
          alignItems="center"
          height="430px"
          borderTopRightRadius={12}
          borderBottomRightRadius={12}
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          backgroundColor="white"
          zIndex={2}
          position="relative"
        >
          <Box width="420px" p={6}>
            <Text mb={4} fontWeight="bold">
              Welcome to
            </Text>
            <Center mb={4}>
              <Image src={Logo} alt="Logo" width="200px" />
            </Center>
            <FormControl id="employeeid" isRequired>
              <InputGroup>
                <Input
                  type="text"
                  height="50px"
                  placeholder="Enter EMPID"
                  value={employeeid}
                  onChange={(e) => setEmployeeid(e.target.value)}
                  focusBorderColor="red.500"
                  borderColor="red.500"
                  borderWidth="2px"
                  borderRadius="40px"
                  autoComplete="off"
                />
                <InputRightElement width="4.5rem">
                  <Icon as={FaUser} color="gray.500" mt={2} />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl id="password" mt={4} isRequired>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  height="50px"
                  borderRadius="40px"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  focusBorderColor="red.500"
                  borderColor="red.500"
                  borderWidth="2px"
                  autoComplete="off"
                />
                <InputRightElement width="4.5rem">
                  <IconButton
                    h="1.75rem"
                    size="sm"
                    mt={3}
                    onClick={handleTogglePassword}
                    icon={
                      showPassword ? (
                        <Icon as={ViewOffIcon} />
                      ) : (
                        <Icon as={ViewIcon} />
                      )
                    }
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            {/* Conditionally render the new input field */}
            {employeeid && password && (
              <FormControl id="otp" mt={4} isRequired>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    height="50px"
                    placeholder="Enter MFA"
                    value={otp}
                    onChange={(e) => setotp(e.target.value)}
                    focusBorderColor="red.500"
                    borderColor="red.500"
                    borderWidth="2px"
                    borderRadius="40px"
                    autoComplete="off"
                  />
                </InputGroup>
              </FormControl>
            )}

            <Button
              backgroundColor="#d00902"
              color="#fff"
              mt={6}
              width="full"
              borderRadius="40px"
              onClick={handleLogin}
            >
              Login
            </Button>

            <>
              <Flex mt={4} justifyContent="space-between">
                <Text>
                  <Link color="blue.500" onClick={onOpen}>
                    Forgot password
                  </Link>
                </Text>
              </Flex>

              <Modal
                isOpen={isOpen}
                onClose={() => {
                  onClose();
                }}
              >
                <ModalOverlay />
                <ModalContent mt={2} height={"100px"}>
                  <ModalCloseButton />
                  <ModalBody mt={8} fontSize={"xl"} fontWeight={"bold"}>
                    Please Contact Admin
                  </ModalBody>
                </ModalContent>
              </Modal>
            </>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Login;
