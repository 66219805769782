import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Icon,
  Image,
  Text,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  useDisclosure,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { ExternalLinkIcon, CalendarIcon } from "@chakra-ui/icons";
import { IoMdNotifications } from "react-icons/io";
import { BsChatDots } from "react-icons/bs";
import { MdWork } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Birthday from "../../Components/Home/Birthday";
import CheckIn from "../../Components/Attendance/CheckIn";
import Timer from "../../Components/Attendance/Timer";
import { getReward } from "../../Redux/AppReducer/Action";
import { getLeaveDetails } from "../../Redux/AppReducer/Action";
import { Doughnut } from "react-chartjs-2";
import Img from "../../Components/Images/awardBg.jpg";
import Awards from "../../Components/Images/award.png";
import Profile from "../../Components/Images/user-profile.png";
import CustomCalendar from "../../Components/Home/CustomCalendar";

const EmployeeDashboard = ({ name, profileImage }) => {
  const user = useSelector((store) => store.auth.user);
  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [clockedIn, setClockedIn] = useState(false);
  const [time, setTime] = useState(0); // time in seconds
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [nextHoliday, setNextHoliday] = useState(null);
  // const [leaveData, setLeaveData] = useState();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const getallReward = useSelector((state) => state.app.GETREWARDs);
  console.log(getallReward);
  useEffect(() => {
    dispatch(getReward(token));
  }, [dispatch, token]);

  const employeeLeave = useSelector((state) => state.app.GETLEAVEDETAILSs);
  const { colorMode } = useColorMode();
  useEffect(() => {
    dispatch(getLeaveDetails(token));
  }, [dispatch, token]);
  const [employeeOfTheMonth, setEmployeeOfTheMonth] = useState({
    name: "",
    profileImage: "",
    designation: "",
    description: "",
  });
  const handleClockIn = () => {
    setClockedIn(true);
  };
  const formatTime = () => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours} Hrs : ${minutes} Mins : ${seconds} Secs`;
  };
  const getLeaveData = (label, value, color, hoverColor) => ({
    labels: [label],
    datasets: [
      {
        data: [value, 24 - value],
        backgroundColor: [color, "#E0E0E0"],
        hoverBackgroundColor: [hoverColor, "#86B6F6"],
      },
    ],
  });
  const totalLeaves = employeeLeave?.TotalLeaves || 0;
  const leaveTaken = employeeLeave?.LeaveTaken || 0;
  const remainingLeaves = employeeLeave?.RemainingLeaves || 0;
  const leaveData = {
    labels: ["Leave Taken", "Total Leave"],
    datasets: [
      {
        data: [leaveTaken, totalLeaves],
        backgroundColor: ["#E74242", "#36A2EB"],
        hoverBackgroundColor: ["#36A2EB", "#FF6384"],
      },
    ],
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };


   const customHolidays = [
    { date: "2025-01-14", name: "Makar Sankranti" },
    { date: "2025-01-26", name: "Republic Day" },
    { date: "2025-02-26", name: "Maha Shivaratri" },
    { date: "2025-03-30", name: "Ugadi" },
    { date: "2025-05-01", name: "Workers Day" },
    { date: "2025-08-15", name: "Independence Day" },
    { date: "2025-08-27", name: "Ganesh Chaturthi" },
    { date: "2025-09-04", name: "Eid-e-Milad" },
    { date: "2025-10-02", name: "Dussehra/Gandhi Jayanti" },
    { date: "2025-10-20", name: "Diwali/Naraka Chaturdasi" },
    { date: "2025-10-22", name: "Govardhan Puja" },
    { date: "2025-12-25", name: "Christmas" },
  ];

  // function to calculate the next Upcoming holiday
  const getNextHoliday = () =>{
    const currentDate = new Date();
    const UpcomingHoliday = customHolidays.find((holiday) => {
      const holidayDate = new Date(holiday.date);
      return holidayDate >= currentDate;
    })
    return UpcomingHoliday;
  }

  useEffect(() => {
    const holiday = getNextHoliday();
    setNextHoliday(holiday);
  },[])


  return (
    <Box
      mt="70px"
      ml="280px"
      background={colorMode === "dark" ? "dark" : "#fafad4"}
      p={4}
    >
      <Box>
        <Flex direction="row" justify="space-between">
          <Box
            margin={"auto"}
            width="25%"
            border="2px solid #e04141"
            borderRadius="10px"
            boxShadow={
              colorMode === "dark"
                ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
            }
            background={
              colorMode === "dark"
                ? "dark"
                : "linear-gradient(90deg, #f0494f 0%, #dad466 100%)"
            }
            p={6}
            height={300}
          >
            <Flex direction="column" align="center">
              {/* Profile Info */}
              <Box textAlign="center">
                <Text fontSize="2xl" fontWeight="bold" mb={4}>
                  {user?.employeeName === undefined ? (
                    <Link to="/login">Welcome, Name</Link>
                  ) : (
                    user?.employeeName
                  )}
                </Text>
              </Box>
              <Image
                borderRadius="full"
                boxSize="100px"
                src={user.profilePicture || Profile}
                alt={`${name}'s profile picture`}
                mt={6}
              />
              <Box textAlign="left" mt={2} fontSize={"18"}>
                <Flex>
                  <Link color="teal.500" to="/EmployeeProfilePage" isExternal>
                    View Profile <Icon as={ExternalLinkIcon} mx="2px" />
                  </Link>
                </Flex>
              </Box>
            </Flex>
          </Box>
          <Box
            margin={"auto"}
            width="72%"
            borderRadius="10px"
            p={2.5}
            border="2px solid #e04141"
            backgroundColor={colorMode === "dark" ? "dark.700" : "#fae8e1"}
            boxShadow={
              colorMode === "dark"
                ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
            }
          >
            <Grid templateColumns="repeat(4, 1fr)" gap={5} mb={6}>
              <Box
                width={220}
                height={230}
                p={5}
                shadow="md"
                borderWidth="1px"
                borderRadius="10px"
                textAlign="center"
                border="2px solid #e04141"
                boxShadow={
                  colorMode === "dark"
                    ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                    : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
                }
                backgroundColor={colorMode === "dark" ? "dark.700" : "#fae8e1"}
              >
                <Text fontSize="md" fontWeight="bold">
                  Total Leave
                </Text>
                <Box width="150px">
                  <Doughnut
                    data={getLeaveData("Total Leave", totalLeaves, "#86B6F6")}
                  />
                </Box>
                <Text mt={2} fontSize="md" textAlign="center">
                  {totalLeaves} Days
                </Text>
              </Box>
              <Box
                width={220}
                height={230}
                p={5}
                shadow="md"
                borderWidth="1px"
                borderRadius="10px"
                textAlign="center"
                border="2px solid #e04141"
                boxShadow={
                  colorMode === "dark"
                    ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                    : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
                }
                backgroundColor={colorMode === "dark" ? "dark.700" : "#fae8e1"}
              >
                <Text fontSize="md" fontWeight="bold">
                  Leave Taken
                </Text>
                <Box width="150px">
                  <Doughnut
                    data={getLeaveData("Leave Taken", leaveTaken, "#D9D22E")}
                  />
                </Box>
                <Text mt={2} fontSize="md" textAlign="center">
                  {leaveTaken} Days
                </Text>
              </Box>
              <Box
                width={220}
                height={230}
                p={5}
                shadow="md"
                borderWidth="1px"
                borderRadius="10px"
                textAlign="center"
                border="2px solid #e04141"
                boxShadow={
                  colorMode === "dark"
                    ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                    : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
                }
                backgroundColor={colorMode === "dark" ? "dark.700" : "#fae8e1"}
              >
                <Text fontSize="md" fontWeight="bold">
                  Remaining Leaves
                </Text>
                <Box width="150px">
                  <Doughnut
                    data={getLeaveData(
                      "Remaining Leaves",
                      remainingLeaves,
                      "#E74242"
                    )}
                  />
                </Box>
                <Text mt={2} fontSize="md" textAlign="center">
                  {remainingLeaves} Days
                </Text>
              </Box>
            </Grid>
            <Box textAlign="left" mt={2} fontSize={"18"}>
              <Link color="teal.500" to="/Leave" isExternal>
                Apply Leave <Icon as={ExternalLinkIcon} mx="2px" />
              </Link>
            </Box>
          </Box>
        </Flex>
      </Box>
      <Box mt={8}>
        <Flex>
          <Box
            ml={2}
            mr={4}
            width="45%"
            borderRadius="md"
            border="2px solid #e04141"
            p={4}
            background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
            boxShadow={
              colorMode === "dark"
                ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
            }
            // boxShadow={"4px 4px 4px 4px rgba(242, 242, 148)"}
          >
            <Heading mb={4} fontSize="2xl" fontWeight="bold" textAlign="left">
              Statistics
            </Heading>
            <Box>
              <Flex
                justify="space-between"
                align="center"
                p={4}
                border="1px solid #ccc"
                borderRadius="md"
                w="100%"
                background={colorMode === "dark" ? "dark" : "#fae8e1"}
                mb={5}
              >
                <Timer
                  setIsCheckedIn={setIsCheckedIn}
                  setCheckInTime={setCheckInTime}
                  setCheckOutTime={setCheckOutTime}
                />
                <CheckIn />
              </Flex>
            </Box>
            <Divider colorScheme={"grey"} />
            <Box textAlign="left" mt={2} fontSize={"18"}>
              <Link color="teal.500" to="/Attendance" isExternal>
                View Attendance <Icon as={ExternalLinkIcon} mx="2px" />
              </Link>
            </Box>
          </Box>
          <Box
            width="52%"
            borderRadius="md"
            border="2px solid #e04141"
            p={6}
            background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
            boxShadow={
              colorMode === "dark"
                ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
            }
          >
            <Heading mb={4} fontSize="2xl" fontWeight="bold" textAlign="left">
              Upcoming Holidays
            </Heading>
            <Box>
              <Flex
                align="center"
                p={4}
                border="1px solid #ccc"
                borderRadius="md"
                w="100%"
                justify={"space-between"}
              >
                {nextHoliday ? (
                  <>
                  <Box>
                      <Flex>
                        <Icon as={CalendarIcon} boxSize={8} mr={4} />
                        <Box>
                          <Text fontSize="xl" fontWeight="bold">
                            {nextHoliday.name}
                          </Text>
                          <Text fontSize="md" color="gray.600">
                            {new Date(nextHoliday.date).toLocaleDateString(
                              "en-US",
                              {
                                weekday: "short",
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }
                            )}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                    <Button colorScheme="teal" ml={4} onClick={onOpen}>
                      View All
                    </Button>
                  </>
                ) : (
                  <Text>No upcoming holidays</Text>
                )}

              </Flex>
            </Box>
          </Box>
        </Flex>
      </Box>

      {/* Modle for CustomCalendar */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Calendar</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <CustomCalendar leave={customHolidays.map(holiday => holiday.date)} />
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>

      
      <Box ml={2} mr={2}>
        <Birthday />
      </Box>
      <Box
        margin="auto"
        width="98%"
        mt={10}
        borderRadius="md"
        border="2px solid #e04141"
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
      >
        <Box
          width="auto"
          height="auto"
        >
          {getallReward?.rewards?.map((award) => (
            <Flex>
              <Box textAlign="left" width="70%" p={6}>
                <Heading
                  as="h2"
                  fontSize="2xl"
                  fontWeight="bold"
                  mb={4}
                  textAlign="left"
                >
                  {award.title}
                </Heading>
                <Text mb={4} textAlign="justify">
                  {award.description}
                </Text>
                <Text
                  textAlign="left"
                  fontStyle="italic"
                  mb={6}
                  fontWeight={"bold"}
                >
                  {formatDate(award.date)}
                </Text>
              </Box>

              <Box textAlign="right">
                <Box textAlign="right">
                  <Image
                    src= {Awards}
                    alt="Description of the image" 
                    boxSize="300px" 
                    objectFit="cover" 
                    
                  />
                </Box>
              </Box>
            </Flex>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
export default EmployeeDashboard;
