import * as types from "./ActionTypes";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js";

export const signin = (payload, toast, navigate) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/login`,
      payload
    );
    const user = jwtDecode(res.data.token);
    const hashedString = CryptoJS.SHA256(payload.password).toString(); // Using SHA-256
    dispatch({ payload: hashedString, type: types.HASHEDPASSWORD_SUCCESS });
    res.data.user = user;
    dispatch({ type: types.SIGNIN_SUCCESS, payload: res.data });
    toast({
      title: "Login success",
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    if (user.role === "user") {
      navigate("/employeedashboard");
    } else {
      navigate("/");
    }
  } catch (err) {
    toast({
      title: err.response?.data?.message,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    dispatch({ type: types.SIGNIN_FAILURE });
  }
};


//Reset Password
export const ResetPassword =
  (token, toast, employeeID, enterOldPassword, newPassword, navigate) =>
  async (dispatch) => {
    // dispatch({ type: types.RESETPASSWORD_REQUEST });

    try {
      const response = axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/resetPassword`,
          {
            employeeID,
            oldPassword: enterOldPassword,
            newPassword,
          },
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        )
        .then(async (response) => {
          dispatch({ type: types.RESETPASSWORD_SUCCESS });

          toast({
            title: response.data.message,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          dispatch({ type: types.SIGNOUT_REQUEST });

          const responses = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/logout`,
            {},
            {
              headers: {
                Authorization: `${token}`,
              },
            }
          );
          // Successful logout
          dispatch({ type: types.SIGNOUT_SUCCESS });
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
          toast({
            title: err.response?.data?.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    } catch (error) {
      
      // dispatch({ type: types.RESETPASSWORD_FAILURE});
    }
  };

// Action to sign up
export const signup = (payload, toast) => async (dispatch) => {
  dispatch({ type: types.SIGNUP_REQUEST });
  return axios
    .post(`${process.env.REACT_APP_BACKEND_URL}/signup`, payload)
    .then((res) => {
      dispatch({ type: types.SIGNUP_SUCCESS });
      toast({
        title: res.data.msg,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    })
    .catch((err) => {
      dispatch({ type: types.SIGNUP_FAILURE });
      toast({
        title: err.response.data.msg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    });
};

// Action to log out
export const logout = (token, toast, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.SIGNOUT_REQUEST });

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/logout`,
      {},
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    // Successful logout
    dispatch({ type: types.SIGNOUT_SUCCESS });
    toast({
      title: response.data.message,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    navigate("/");
  } catch (error) {
    dispatch({ type: types.SIGNOUT_FAILURE });
  }
};
