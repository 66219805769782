// import React, { children } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const Private = ({ children }) => {

   const data = useSelector((store) => store.auth.user)
   if (data?.employeeName === undefined) {
      return <Navigate to="/login" /> 
   } 
   return children

}  

export default Private
