import React from 'react'
import Login from '../Components/Login/Login';
import Home from '../Components/Home/Home';
import Navbar from '../Components/Navbar/Navbar';
import Sidebar from '../Components/Navbar/Sidebar';
import { Box, Text } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom/dist';
import UAN from '../screens/User/UAN';
import PaySlip from '../screens/User/PaySlip';
import Employee from '../screens/Admin/Employee';
import Document from '../screens/User/Document';
import Hike from '../screens/User/Hike';
import Awards_Rewards from '../screens/User/Awards_Rewards';
import Rise_Complaint from '../screens/User/Rise_Complaint';
import Attendance from '../screens/User/Attendance/Attendance';
import Leave from '../screens/User/Leave/Leave';
import Reimbursement from '../screens/User/Reimbursement';
// import Performance from '../screens/User/Performance';
import PunchIn from '../Components/Home/Punch/PunchIn';
import Payroll from '../screens/Admin/Payroll';

import Resign from '../screens/User/Resign';
import AboutUs from '../screens/User/AboutUs';
import Ticket  from '../screens/User/Ticket';
import MyAttendancePage from '../screens/Admin/AttendancePage';
import EmployeeProfilePage from '../screens/User/Employee_Profile';
import LeaveApproval from '../screens/Admin/LeaveApproval';
import Private from './Private';
import Tickets from '../screens/Admin/Tickets';
import AppraisalRequest from '../screens/Admin/AppraisalRequeat';
import ReimbursementPage from '../screens/Admin/Reimbursement';
import Awards from '../screens/Admin/Awards';
import EmployeeDashboard from '../screens/User/EmployeeDashboard';
import Events from '../screens/Admin/Events';
import Referral from '../screens/User/Referral';
import ReferralRequest from '../screens/Admin/ReferralRequests';
import Particle from '../Components/Login/Particle';
import Complaints from '../screens/Admin/Complaints';
import Documents from '../screens/Admin/Documents.js';
import Settings from '../screens/User/Settings';


const AllRoutes = () => {
  return (
    <Routes>
      <Route exact path='/' element={<Private><Navbar/> <Sidebar/> <Home/></Private>} />
      <Route path='/login' element={<><Login /></>} />
      <Route path='/employee' element={<Private><Navbar/> <Sidebar/><Employee/></Private>} />
      <Route exact path='/uan' element={<Private><Navbar/> <Sidebar/><UAN/> </Private>} />
      <Route exact path='/payslip' element={<Private><Navbar/> <Sidebar/><PaySlip/> </Private>} />
      <Route path='/document' element={<Private><Navbar/> <Sidebar/><Document/></Private>} />
      <Route path='/hike' element={<Private><Navbar/> <Sidebar/><Hike/></Private>} />
      <Route path='/awardsandrewards' element={<Private><Navbar/> <Sidebar/><Awards_Rewards/></Private>} />
      <Route path='/rise and complaints' element={<Private><Navbar/> <Sidebar/><Rise_Complaint/></Private>} />
      <Route path='/attendance' element={<Private><Navbar/> <Sidebar/><Attendance/></Private>} />
      <Route path='/attendancepage' element={<Private><Navbar/> <Sidebar/><MyAttendancePage/></Private>} />
      <Route path='/leave' element={<Private><Navbar/> <Sidebar/><Leave/></Private>} />
      {/* <Route path='/performance' element={<Private><Navbar/> <Sidebar/><Performance/></Private>} /> */}
      <Route path='/reimbursement' element={<Private><Navbar/> <Sidebar/><Reimbursement/></Private>} />
      <Route path='/payroll' element={<Private><Navbar/> <Sidebar/><Payroll /></Private>} />
      <Route path='/referral' element={<Private><Navbar/> <Sidebar/><Referral /></Private>} />
      <Route path='/punchin' element={<Private><Navbar/> <Sidebar/><PunchIn /></Private>} />
      <Route path='/resign' element={<Private><Navbar/> <Sidebar/><Resign /></Private>} />
      <Route path='/about' element={<Private><Navbar/> <Sidebar/><AboutUs /></Private>} />
      <Route path='/ticket' element={<Private><Navbar/> <Sidebar/><Ticket /></Private>} />
      <Route path='/employeeprofilepage' element={<Private><Navbar/> <Sidebar/><EmployeeProfilePage /></Private>} />
      <Route path='/leaveapproval' element={<Private><Navbar/> <Sidebar/><LeaveApproval /></Private>} />
      <Route path='/tickets' element={<Private><Navbar/> <Sidebar/><Tickets /></Private>} />
      <Route path='/appraisalrequest' element={<Private><Navbar/> <Sidebar/><AppraisalRequest /></Private>} />
      <Route path='/reimbursementrequest' element={<Private><Navbar/> <Sidebar/><ReimbursementPage /></Private>} />

      <Route path='/awards' element={<Private><Navbar/> <Sidebar/><Awards /></Private>} />
      <Route path='/employeedashboard' element={<Private><Navbar/> <Sidebar/><EmployeeDashboard/></Private>} />

      <Route path='/events' element={<Private><Navbar/> <Sidebar/><Events /></Private>} />
      <Route path='/referralrequests' element={<Private><Navbar/> <Sidebar/><ReferralRequest /></Private>} />
      <Route path='/complaints' element={<Private><Navbar/> <Sidebar/><Complaints /></Private>} />
      <Route path='/documents' element={<Private><Navbar/> <Sidebar/><Documents /></Private>} />
      <Route path='/settings' element={<Private><Navbar/> <Sidebar/><Settings /></Private>} />







    </Routes>
  ) 
}

export default AllRoutes
