import {
  Box,
  Image,
  Text,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Flex,
  ChakraProvider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Center,
  Heading,
  useColorMode,
  FormHelperText,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Profile from "../../Components/Images/user-profile.png"
import { FiMoreVertical } from "react-icons/fi";
import { FaSearch } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";

import { useDispatch, useSelector } from "react-redux";
import {
  deleteEmployee,
  getAllEmployee,
  updateEmployee,
} from "../../Redux/AppReducer/Action";
import { searchEmployee} from "../../Redux/AppReducer/Action";
import { register } from "../../Redux/AppReducer/Action";

const Employee = () => {
  const [searchTerm, setSearchTerm] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isShowAllData, setIsShowAllData] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [newData, setNewData] = useState({});
  const {colorMode} = useColorMode();

  const token = useSelector((state) => state.auth.token);
  const employees = useSelector((state) => state.employee?.employees);
  const SearchEmployee = useSelector((state) => state.app.SEARCHEMPLOYEEs);
  console.log(SearchEmployee)
  const EmployeeData = useSelector((state) => state.app.ALLEMPLOYEEs);
  const [finalemployeesData, setFinalEmployeesData] = useState([]);
  console.log(EmployeeData);
  const searchRef = useRef();
  const [newEmployeeData, setNewEmployeeData] = useState({
    employeeID: "",
    employeeName: "",
    password: "",
    DOB: "",
    gender: "",
    title: "",
    contactInfo: "",
    email: "",
    address: "",
    salary: "",
    employeeStatus: "",
    profilePicture: null,
    PAN: "",
    UAN: "",
    HRA: "",
    specialallowance: "",
    medicalallowance: "",
    transportallowance: "",
    PF: "",
    profesionaltax: "",
    TDS: "",
    ESI: "",
    ESINumber: "",
    employmentHistory: [],
  });
  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isMobileNumberValid = (mobileNumber) => {
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(mobileNumber);
  };

  const isPANValid = (PAN) => {
    const PANNumberRegex = /^[A-Z0-9]{10}$/;
    return PANNumberRegex.test(PAN);
  };

  const displayAlert = (message) => {
    alert(message);
  };

 

  //add new employee details
  const handleOpenAddModal = () => {
    setIsAddModalOpen(true);
  };


  const handleOpenEditModal = (employee) => {
    setSelectedFile(null); // Reset selected file on edit open
    setNewData({ ...employee });
    setIsEditModalOpen(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file); 
    setNewEmployeeData((prevData) => ({
      ...prevData,
      profilePicture: file, 
    }));
    console.log("Selected File:", file); // Debugging log
  };

  const handleAddEmployee = () => {
    const requiredFields = [
      "employeeID",
      "employeeName",
      "password",
      "DOB",
      "gender",
      "title",
      "contactInfo",
      "email",
      "address",
      "salary",
      "employeeStatus",
      "dateOfJoining",
      "employeeHistory"
    
    ];
    const isEmpltyFields = requiredFields.some(
      (fields) => !newEmployeeData[fields]
    );
    // if (isEmpltyFields) {
    //   alert("Please fill all the required fields");
    //   return;
    // }
    if (!isEmailValid(newEmployeeData.email)) {
      alert("Please fill the valid email ID");
      return;
    }
    if (!isMobileNumberValid(newEmployeeData.contactInfo)) {
      alert("Please fill the valid mobile number");
      return;
    }

    // if (!isPANValid(newEmployeeData.PAN)) {
    //   alert("Please fill the valid PAN Number");
    //   return;
    // }

    const formData = new FormData();
    Object.keys(newEmployeeData).forEach((key) => {
      formData.append(key, newEmployeeData[key]);
    });

    if (selectedFile) {
      formData.set("profilePicture", selectedFile); 
      console.log("profilePicture in formData:", selectedFile); 
    }

    dispatch(register(token, formData));

    displayAlert("Employee details updated successfully!");
    dispatch(getAllEmployee(token));

    setIsAddModalOpen(false);
    setNewEmployeeData({
      employeeID: "",
      employeeName: "",
      password: "",
      DOB: "",
      gender: "",
      title: "",
      contactInfo: "",
      email: "",
      address: "",
      salary: "",
      employeeStatus: "",
      profilePicture: "",
      department: "",
      position: "",
      dateOfJoining: "",
      reportingManager: "",
      PAN: "",
      UAN: "",
      HRA: "",
      specialallowance: "",
      medicalallowance: "",
      transportallowance: "",
      PF: "",
      profesionaltax: "",
      TDS: "",
      ESI: "",
      ESINumber: "",
      employmentHistory: [],
    });
  };

  //delete the employee details
  const handleOpenDeleteConfirmation = (employee) => {
    setEmployeeToDelete(employee);
    setIsDeleteConfirmationOpen(true);
  };

  const handleDeleteEmployee = () => {
    dispatch(deleteEmployee(token, employeeToDelete.employeeID)).then(() => {
      setIsDeleteConfirmationOpen(false);
      dispatch(getAllEmployee(token));
    });
  };


  //update the Employee Details
  const handleUpdateEmployeeDetails = () => {
    if (!isEmailValid(newData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    const formData = new FormData();
    Object.keys(newData).forEach((key) => {
      formData.append(key, newData[key]);
    });

    if (selectedFile) {
      formData.set("profilePicture", selectedFile); 
      console.log("profilePicture in formData:", selectedFile); 
    }

    dispatch(updateEmployee(token, newData.employeeID, formData)).then(() => {
      setIsEditModalOpen(false);
      displayAlert("Employee details updated successfully!");
      dispatch(getAllEmployee(token));
    });
  };

  // Show all the data of the employee
  const handleOpenAllData = (employee) => {
    setSelectedEmployee(employee);
    setIsShowAllData(true);
  };

  //Search Employee Details
  const handleSearchEmployee = () => {

   dispatch(searchEmployee(token, searchRef.current.value, EmployeeData))
      
      setFinalEmployeesData(searchEmployee);
    
      
  };

  //fetching the employee data
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(getAllEmployee(token));
    }
  }, [dispatch, token,searchTerm]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };
  

  return (
    <Box p={4} mt={"70px"} ml={"280px"} background={colorMode === "dark" ? "gray.700" : "#fcf7d2"}>
      <Heading fontSize={25} mb={4} textAlign="left">
        Employee List
      </Heading>

      <Flex>
        <FormControl mb={4}>
          <Input
            ref={searchRef}
            width={"80%"}
            mb={8}
            borderColor="red.500"
            marginRight={"10px"}
            type="text"
            placeholder="Search Employee"
            value={searchTerm}
           onChange={(e) => {
            setSearchTerm(e.target.value)
           }}
          />
          <Button
            background="#e04141"
            color={"#FECE01"}
            padding="10px"
            onClick={handleSearchEmployee}
            mt={-2}
          >
            <Center>
              <FaSearch />
            </Center>
          </Button>
        </FormControl>
        <Box width={"50%"} textAlign={"right"} ml={"300px"} >
          <Button background="#e04141" color={"#FECE01"} onClick={handleOpenAddModal} ml={"150px"}>
            <Center background={"white"} mr={"5px"} borderRadius={"10px"}>
              <MdAdd background="#e04141" color={"#FECE01"} />
            </Center>
            Add Employee
          </Button>
        </Box>
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={6} >
        {EmployeeData?.length > 0 &&
          EmployeeData?.map((employee, index) => (
            <GridItem
              key={employee.id}
              borderWidth="1px"
              borderRadius="10px"
              overflow="hidden"
              position="relative"
              p={4}
              border="2px solid #e04141"
              background={colorMode === "dark" ? "gray.700" : "#fae8e1"}
              boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}

            >
               <Image
                src={employee.profilePicture || Profile} // Fallback to default image
                alt={employee.employeeName}
                borderRadius="full"
                boxSize="80px"
                objectFit="cover"
                mx="auto"
                onClick={() => handleOpenAllData(employee)}
              />
              <Box mt={4} textAlign="center">
                <Text fontWeight="bold">{employee.employeeName}</Text>
                <Text color="gray.500">{employee.position}</Text>
              </Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<FiMoreVertical  />}
                  variant="outline"
                  position="absolute"
                  top="8px"
                  right="8px"
                />
                <MenuList minW="unset" width="150px">
                  <MenuItem
                    height={"30px"}
                    icon={<EditIcon />}
                    onClick={() => handleOpenEditModal(employee)}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    height={"30px"}
                    icon={<DeleteIcon />}
                    onClick={() => handleOpenDeleteConfirmation(employee)}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </GridItem>
          ))}
      </Grid>

      <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
        <ModalOverlay />
        <ModalContent mt={"120px"} maxW={"800px"} ml={"300px"}>
          <ModalHeader>Edit Employee Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Employee ID</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Employee ID"
                    value={newData.employeeID}
                    type="text"
                    onChange={(e) =>
                      setNewData({ ...newData, employeeID: e.target.value })
                    }
                    isDisabled={isEditModalOpen}
                  />
                  
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Title</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Title"
                    type="text"
                    value={newData.title}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        title: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Employee Name</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Employee Name"
                    value={newData.employeeName}
                    type="text"
                    onChange={(e) =>
                      setNewData({ ...newData, employeeName: e.target.value })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Profile Image</FormLabel>
                   <Input type="file" onChange={handleFileChange} />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Date of Birth</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Enter DOB in DD/MM/YYYY"
                    type="text"
                    value={formatDate(newData.DOB)}
                    onChange={(e) =>
                      setNewData({ ...newData, DOB: e.target.value })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Gender</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Gender"
                    value={newData.gender}
                    onChange={(e) =>
                      setNewData({ ...newData, gender: e.target.value })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Contact Number</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Contact Number"
                    type="tel"
                    value={newData.contactInfo}
                    onChange={(e) =>
                      setNewData({ ...newData, contactInfo: e.target.value })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Email"
                    type="email"
                    value={newData.email}
                    onChange={(e) =>
                      setNewData({ ...newData, email: e.target.value })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Department</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Department"
                    type="text"
                    value={newData.department}
                    onChange={(e) =>
                      setNewData({ ...newData, department: e.target.value })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Position</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Position"
                    type="text"
                    value={newData.position}
                    onChange={(e) =>
                      setNewData({ ...newData, position: e.target.value })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Joining Date</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Enter Joining Date in DD/MM/YYYY"
                    type="text"
                    value={formatDate(newData.dateOfJoining)}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        dateOfJoining: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Address</FormLabel>
                  <Input
                    mb={3}
                    placeholder="address"
                    type="text"
                    value={newData.address}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        address: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Basic Salary</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Salary"
                    type="text"
                    value={newData.salary}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        salary: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Reporting Manager</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Reporting Manager"
                    type="text"
                    value={newData.reportingManager}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        reportingManager: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>EmployeeStatus</FormLabel>
                  <Input
                    mb={3}
                    placeholder="employeeStatus"
                    type="text"
                    value={newData.employeeStatus}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        employeeStatus: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>PAN</FormLabel>
                  <Input
                    mb={3}
                    placeholder="PAN Number"
                    type="text"
                    value={newData.PAN}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        PAN: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>UAN Number </FormLabel>
                  <Input
                    mb={3}
                    placeholder="UAN Number"
                    type="text"
                    value={newData.UAN}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        UAN: e.target.value,
                      })
                    }
                  />
                </FormControl>
              
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel> HRA</FormLabel>
                  <Input
                    mb={3}
                    placeholder="HRA"
                    type="number"
                    value={newData.HRA || ""}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        HRA: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Special Allowance</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Special  Allowance"
                    type="number"
                    value={newData.specialallowance}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        specialallowance: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Medical Allowance</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Medical  Allowance"
                    type="number"
                    value={newData.medicalallowance}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        medicalallowance: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Transport Allowance</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Transport  Allowance"
                    type="number"
                    value={newData.transportallowance}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        transportallowance: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>PF </FormLabel>
                  <Input
                    mb={3}
                    placeholder="PF"
                    type="number"
                    value={newData.PF}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        PF: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Professional Tax</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Professional  Tax"
                    type="number"
                    value={newData.profesionaltax}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        profesionaltax: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>TDS</FormLabel>
                  <Input
                    mb={3}
                    placeholder="TDS "
                    type="number"
                    value={newData.TDS}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        TDS: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>ESI</FormLabel>
                  <Input
                    mb={3}
                    placeholder="ESI Amount"
                    type="number"
                    value={newData.ESI}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        ESI: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>ESI Number</FormLabel>
                  <Input
                    mb={3}
                    placeholder="ESI  Number"
                    type="text"
                    value={newData.ESINumber}
                    onChange={(e) =>
                      setNewData({
                        ...newData,
                        ESINumber: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleUpdateEmployeeDetails}
            >
              Save
            </Button>
            <Button onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
      >
        <ModalOverlay />
        <ModalContent position={"absolute"} top={"30%"} left={"40%"}>
          <ModalHeader>Delete Employee</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete{" "}
            {employeeToDelete && employeeToDelete.employeeID}?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDeleteEmployee}>
              Yes, Delete
            </Button>
            <Button onClick={() => setIsDeleteConfirmationOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)}>
        <ModalOverlay />
        <ModalContent mt={"120px"} maxW={"800px"} ml={"300px"}>
          <ModalHeader>Add New Employee</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Employee ID <span style={{ color: "red" }}>*</span></FormLabel>
                  <Input
                    mb={3}
                    placeholder="Employee ID"
                    type="text"
                    value={newEmployeeData.employeeID}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        employeeID: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Title <span style={{ color: "red" }}>*</span> </FormLabel>
                  <Select
                    mb={3}
                    placeholder="Select Title"
                    value={newEmployeeData.title}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        title: e.target.value,
                      })
                    }
                  >
                    <option value="" hidden>
                      Select Title
                    </option>
                    <option value={"Mr"}>Mr</option>
                    <option value={"Ms"}>Ms</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Employee Name <span style={{ color: "red" }}>*</span> </FormLabel>
                  <Input
                    mb={3}
                    placeholder="Employee Name"
                    type="text"
                    value={newEmployeeData.employeeName}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        employeeName: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Password <span style={{ color: "red" }}>*</span> </FormLabel>
                  <Input
                    mb={3}
                    placeholder="Password"
                    type="text"
                    value={newEmployeeData.password}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        password: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Profile Image</FormLabel>
                  <Input type="file" onChange={handleFileChange} />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Date of Birth <span style={{ color: "red" }}>*</span> </FormLabel>
                  <Input
                    mb={3}
                    placeholder="Date of Birth"
                    type="date"
                    value={newEmployeeData.DOB}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        DOB: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Gender <span style={{ color: "red" }}>*</span> </FormLabel>
                  <Select
                    mb={3}
                    placeholder="Gender"
                    value={newEmployeeData.gender}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        gender: e.target.value,
                      })
                    }
                  >
                    <option value="" hidden>
                      Select Gender
                    </option>
                    <option value={"male"}>Male</option>
                    <option value={"female"}>Female</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Contact Number <span style={{ color: "red" }}>*</span></FormLabel>
                  <Input
                    mb={3}
                    placeholder="Contact Number"
                    type="tel"
                    value={newEmployeeData.contactInfo}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        contactInfo: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>E-mail <span style={{ color: "red" }}>*</span></FormLabel>
                  <Input
                    mb={3}
                    placeholder="E-mail"
                    type="email"
                    value={newEmployeeData.email}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        email: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Department <span style={{ color: "red" }}>*</span></FormLabel>
                  <Select
                    mb={3}
                    placeholder="Select"
                    type="text"
                    value={newEmployeeData.department}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        department: e.target.value,
                      })
                    }
                  >
                    <option value="" hidden>
                      Select Department
                    </option>
                    <option value={"Management"}>Management</option>
                    <option value={"Operation"}>Operations</option>
                    <option value={"HR"}>HR</option>
                    <option value={"Sales"}>Sales</option>
                    <option value={"Marketing"}>Marketing</option>
                    <option value={"Consulting"}>Consulting</option>
                    <option value={"Development"}>Development</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Position <span style={{ color: "red" }}>*</span></FormLabel>
                  <Input
                    mb={3}
                    placeholder="Position"
                    type="text"
                    value={newEmployeeData.position}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        position: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Joining Date <span style={{ color: "red" }}>*</span></FormLabel>
                  <Input
                    mb={3}
                    placeholder="Joining Date"
                    type="date"
                    value={newEmployeeData.dateOfJoining}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        dateOfJoining: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Address <span style={{ color: "red" }}>*</span></FormLabel>
                  <Input
                    mb={3}
                    placeholder="Address"
                    type="text"
                    value={newEmployeeData.address}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        address: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Basic Salary <span style={{ color: "red" }}>*</span></FormLabel>
                  <Input
                    mb={3}
                    placeholder="Salary"
                    type="text"
                    value={newEmployeeData.salary}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        salary: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Reporting Manager <span style={{ color: "red" }}>*</span></FormLabel>
                  <Input
                    mb={3}
                    placeholder="Reporting Manager"
                    type="text"
                    value={newEmployeeData.reportingManager}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        reportingManager: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>EmployeeStatus <span style={{ color: "red" }}>*</span></FormLabel>

                  <Select
                    mb={3}
                    placeholder="EmployeeStatus"
                    type="text"
                    value={newEmployeeData.employeeStatus}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        employeeStatus: e.target.value,
                      })
                    }
                  >
                    <option value="" hidden>
                      Select Status
                    </option>
                    <option value={"Full-time"}>Full-time</option>
                    <option value={"Part-time"}>Part-time</option>
                    <option value={"Contractual"}>Contractual</option>
                    <option value={"Intern"}>Intern</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>PAN Number</FormLabel>
                  <Input
                    mb={3}
                    placeholder="PAN Number"
                    type="text"
                    value={newEmployeeData.PAN}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        PAN: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>UAN Number </FormLabel>
                  <Input
                    mb={3}
                    placeholder="UAN Number"
                    type="text"
                    value={newEmployeeData.UAN}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        UAN: e.target.value,
                      })
                    }
                  />
                </FormControl>
              
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel> HRA</FormLabel>
                  <Input
                    mb={3}
                    placeholder="HRA"
                    type="text"
                    value={newEmployeeData.HRA}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        HRA: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Special Allowance</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Special  Allowance"
                    type="text"
                    value={newEmployeeData.specialallowance}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        specialallowance: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Medical Allowance</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Medical  Allowance"
                    type="text"
                    value={newEmployeeData.medicalallowance}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        medicalallowance: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Transport Allowance</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Transport  Allowance"
                    type="text"
                    value={newEmployeeData.transportallowance}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        transportallowance: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>PF </FormLabel>
                  <Input
                    mb={3}
                    placeholder="PF"
                    type="text"
                    value={newEmployeeData.PF}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        PF: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>Professional Tax</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Professional  Tax"
                    type="text"
                    value={newEmployeeData.profesionaltax}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        profesionaltax: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>TDS</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Transport  Allowance"
                    type="text"
                    value={newEmployeeData.TDS}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        TDS: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>ESI</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Transport  Allowance"
                    type="text"
                    value={newEmployeeData.ESI}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        ESI: e.target.value,
                      })
                    }
                    isRequired
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel>ESI Number</FormLabel>
                  <Input
                    mb={3}
                    placeholder="Transport  Allowance"
                    type="text"
                    value={newEmployeeData.ESINumber}
                    onChange={(e) =>
                      setNewEmployeeData({
                        ...newEmployeeData,
                        ESINumber: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Flex gap={8}>
              <Button onClick={() => setIsAddModalOpen(false)}>Cancel</Button>
              <Button colorScheme="blue" mr={3} onClick={handleAddEmployee}>
                Add Employee
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

   <Modal isOpen={isShowAllData} onClose={() => setIsShowAllData(false)}>
        <ModalOverlay />
        <ModalContent mt={"120px"} maxW={"800px"} ml={"300px"}>
          <ModalHeader margin={"auto"} color={"teal"}>
            {" "}
            Employee Details
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedEmployee && (
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <React.Fragment>
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel>Employee ID</FormLabel>
                      <Input
                        mb={3}
                        placeholder="Employee ID"
                        value={selectedEmployee.employeeID}
                        type="text"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel>Title</FormLabel>
                      <Input
                        mb={3}
                        placeholder="Title"
                        type="text"
                        value={selectedEmployee.title}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel>Employee Name</FormLabel>
                      <Input
                        mb={3}
                        placeholder="Employee Name"
                        value={selectedEmployee.employeeName}
                        type="text"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel>Date of Birth</FormLabel>
                      <Input
                        mb={3}
                        placeholder="Date of Birth"
                        type="text"
                        value={formatDate(selectedEmployee.DOB)}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel>Gender</FormLabel>
                      <Input
                        mb={3}
                        placeholder="Gender"
                        value={selectedEmployee.gender}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel>Contact Number</FormLabel>
                      <Input
                        mb={3}
                        placeholder="Contact Number"
                        type="tel"
                        value={selectedEmployee.contactInfo}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel>Email</FormLabel>
                      <Input
                        mb={3}
                        placeholder="Email"
                        type="email"
                        value={selectedEmployee.email}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel>Department</FormLabel>
                      <Input
                        mb={3}
                        placeholder="Department"
                        type="text"
                        value={selectedEmployee.department}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel>Position</FormLabel>
                      <Input
                        mb={3}
                        placeholder="Position"
                        type="text"
                        value={selectedEmployee.position}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel>Joining Date</FormLabel>
                      <Input
                        mb={3}
                        placeholder="Joining Date"
                        type="text"
                        value={formatDate(selectedEmployee.dateOfJoining)}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel>Address</FormLabel>
                      <Input
                        mb={3}
                        placeholder="address"
                        type="text"
                        value={selectedEmployee.address}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel>Salary</FormLabel>
                      <Input
                        mb={3}
                        placeholder="Salary"
                        type="text"
                        value={selectedEmployee.salary}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel>Reporting Manager</FormLabel>
                      <Input
                        mb={3}
                        placeholder="Reporting Manager"
                        type="text"
                        value={selectedEmployee.reportingManager}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel>Employee Status</FormLabel>
                      <Input
                        mb={3}
                        placeholder="employeeStatus"
                        type="text"
                        value={selectedEmployee.employeeStatus}
                      />
                    </FormControl>
                  </GridItem>
            
                </React.Fragment>
              </Grid>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

    </Box>
  );
};

export default Employee;
