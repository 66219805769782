import authHeader from "../../auth-header";
import * as types from "./ActionTypes";
import axios from "axios";
import { Reducer } from "../AuthReducer/Reducer";

export const getDashboardDetails = (token) => async (dispatch) => {
  dispatch({ type: types.DASHBOARD_DETAILS_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getDashboardDetails`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.DASHBOARD_DETAILS_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.DASHBOARD_DETAILS_FAILURE });
  }
};

//Allemployee
export const getAllEmployee = (token) => async (dispatch) => {
  dispatch({ type: types.ALLEMPLOYEE_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getAllEmployee`,

      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.ALLEMPLOYEE_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.ALLEMPLOYEE_FAILURE });
  }
};
// this is for search employee
// export const filterEmployees = (employeeID, employeeData) => (dispatch) => {
//   const employee = employeeData.filter((employee) =>
//     employee.employeeID.includes(employeeID)
//   );
//   dispatch({ type: types.SEARCHEMPLOYEE_SUCCESS, payload: employee });
// };

//Attendance(User)
export const getAllAttendance = (token) => async (dispatch) => {
  dispatch({ type: types.ALLATTENDANCE_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getAttendanceDetails`,

      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    let checkInTime = "";
    let checkOutTime = "";
    const data = response.data;
    function getCurrentDateFormatted() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    }
    //validation checking
    const date = getCurrentDateFormatted();
    const filterAttendance = data?.attendanceRecords?.filter(
      (event) => event.date === date
    );
    if (filterAttendance.length > 0) {
      checkInTime = filterAttendance[0].checkInTime.replace(" ", "T");
      if (filterAttendance[0].checkOutTime != "Not checked out") {
        checkOutTime = filterAttendance[0].checkOutTime.replace(" ", "T");
      }
    }
    data.checkInTime = checkInTime;
    data.checkOutTime = checkOutTime;
    dispatch({ type: types.ALLATTENDANCE_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.ALLATTENDANCE_FAILURE });
  }
};

//Attendance(Admin)
export const getAttendanceOverview = (token) => async (dispatch) => {
  dispatch({ type: types.ATTENDANCEOVERVIEW_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/attendanceOverview`,

      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.ATTENDANCEOVERVIEW_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.ATTENDANCEOVERVIEW_FAILURE });
  }
};

//Attendance Logs search(Admin)

const validateDate = (date) => {
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
  return datePattern.test(date) && !isNaN(new Date(date).getTime());
};

export const getAttendanceLogs =
  (token, employeeID, fromDate, toDate) => async (dispatch) => {
    dispatch({ type: types.ATTENDANCELOGS_REQUEST });

    // Validate dates
    if (!validateDate(fromDate) || !validateDate(toDate)) {
      console.log("Invalid date format. Dates must be in YYYY-MM-DD format.");
      dispatch({
        type: types.ATTENDANCELOGS_FAILURE,
        error: "Invalid date format.",
      });
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/attendance/search`,
        {
          params: {
            fromDate,
            toDate,
            employeeID,
          },
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      const data = response.data;
      dispatch({ type: types.ATTENDANCELOGS_SUCCESS, payload: data });
      // console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.ATTENDANCELOGS_FAILURE, error: error.message });
    }
  };

// Review Attendance (Admin)

export const getReviewAttendance =
  (token, employeeID, fromDate, toDate) => async (dispatch) => {
    dispatch({ type: types.GETREVIEWATTENDANCE_REQUEST });

    // Validate dates
    if (!validateDate(fromDate) || !validateDate(toDate)) {
      console.log("Invalid date format. Dates must be in YYYY-MM-DD format.");
      dispatch({
        type: types.GETREVIEWATTENDANCE_FAILURE,
        error: "Invalid date format.",
      });
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/reviewAttendance`,
        {
          params: {
            employeeID,
            fromDate,
            toDate,
          },
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      const data = response.data;
      dispatch({ type: types.GETREVIEWATTENDANCE_SUCCESS, payload: data });
      // console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({
        type: types.GETREVIEWATTENDANCE_FAILURE,
        error: error.message,
      });
    }
  };

// Approved Attendance (Admin)

export const ApprovedAttendance =
  (token, attendanceIDs) => async (dispatch) => {
    dispatch({ type: types.APPROVEDATTENDANCE_REQUEST });

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/approveAttendance`,
        { attendanceIDs }, // Sending the array of attendance IDs in the request body
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.APPROVEDATTENDANCE_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.APPROVEDATTENDANCE_FAILURE });
    }
  };

//Download Attendance(Admin)

export const downloadAttendance =
  (token, employeeID, fromDate, toDate) => async (dispatch) => {
    dispatch({ type: types.DOWNLOADATTENDANCE_REQUEST });

    // Validate dates
    if (!validateDate(fromDate) || !validateDate(toDate)) {
      console.log("Invalid date format. Dates must be in YYYY-MM-DD format.");
      dispatch({
        type: types.DOWNLOADATTENDANCE_FAILURE,
        error: "Invalid date format.",
      });
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/attendance/download`,
        {
          params: {
            employeeID,
            fromDate,
            toDate,
            download: true,
          },
          headers: {
            Authorization: `${token}`,
          },
          responseType: "blob", // Ensuring that the response is treated as a binary large object (blob)
        }
      );

      // Create a URL for the blob response
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `attendance_${employeeID}_${fromDate}_to_${toDate}.xlsx`
      );

      // Append to the body and trigger click to download
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the link element and revoke the object URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      dispatch({ type: types.DOWNLOADATTENDANCE_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: types.DOWNLOADATTENDANCE_FAILURE,
        error: error.message,
      });
    }
  };

// createEmployee
export const register = (token, updatedData) => async (dispatch) => {
  dispatch({ type: types.REGISTER_REQUEST });

  try {
    let requestData = updatedData;
    let headers = {
      Authorization: `${token}`,
    };

    if (updatedData instanceof FormData) {
      headers["Content-Type"] = "multipart/form-data";
    } else {
      headers["Content-Type"] = "application/json";
    }
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/register`,
      requestData,
      { headers }
    );

    const data = response.data;
    dispatch({ type: types.REGISTER_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log("Error during registration:", error);
    dispatch({ type: types.REGISTER_FAILURE });
  }
};

//Search employee
export const searchEmployee = (token, idOrName) => async (dispatch) => {
  dispatch({ type: types.SEARCHEMPLOYEE_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/employees/${idOrName}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const data = response.data;
    dispatch({ type: types.SEARCHEMPLOYEE_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.SEARCHEMPLOYEE_FAILURE });
  }
};

//Update employee
export const updateEmployee = (token, id, updatedData) => async (dispatch) => {
  dispatch({ type: types.UPDATEEMPLOYEE_REQUEST });

  try {
    let requestData = updatedData;
    let headers = {
      Authorization: `${token}`,
    };

    if (updatedData instanceof FormData) {
      headers["Content-Type"] = "multipart/form-data";
    } else {
      headers["Content-Type"] = "application/json";
    }

    console.log("Sending request with headers:", headers); // Debugging headers
    console.log(
      "Sending request with data:",
      updatedData.get("profilePicture")
    ); // Debugging FormData

    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/updateEmployee/user/${id}`,
      requestData,
      { headers }
    );

    const data = response.data;
    dispatch({ type: types.UPDATEEMPLOYEE_SUCCESS, payload: data });
    console.log("Employee updated successfully:", data);
  } catch (error) {
    console.error("Error updating employee:", error);
    const errorMessage = error.response?.data?.message || error.message;
    dispatch({ type: types.UPDATEEMPLOYEE_FAILURE, payload: errorMessage });
  }
};

//Delete employee
export const deleteEmployee = (token, id) => async (dispatch) => {
  dispatch({ type: types.DELETEEMPLOYEE_REQUEST });

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/deleteEmployee/${id}`,
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = response.data;
    dispatch({ type: types.DELETEEMPLOYEE_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.DELETEEMPLOYEE_FAILURE });
  }
};

//CheckIn Time

export const checkInEmployee = (token) => async (dispatch) => {
  dispatch({ type: types.CHECKINEMPLOYEE_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/checkin`,
      {},
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.CHECKINEMPLOYEE_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.CHECKINEMPLOYEE_FAILURE });
  }
};

// CheckOut Time

export const checkOutEmployee = (token) => async (dispatch) => {
  dispatch({ type: types.CHECKOUTEMPLOYEE_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/checkout`,
      {},
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.CHECKOUTEMPLOYEE_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.CHECKOUTEMPLOYEE_FAILURE });
  }
};

//Apply Leave(User)

export const createLeave = (token, data) => async (dispatch) => {
  dispatch({ type: types.CREATELEAVE_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/createLeave`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseData = response.data;
    dispatch({ type: types.CREATELEAVE_SUCCESS, payload: data });
    console.log(responseData);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.CREATELEAVE_FAILURE });
  }
};

// Get All Leave(User)

export const getUserLeave = (token) => async (dispatch) => {
  dispatch({ type: types.GETUSERLEAVE_REQUEST });
  console.log(token);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getUserLeavesdef`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responseData = response.data;
    dispatch({ type: types.GETUSERLEAVE_SUCCESS, payload: responseData });
    console.log(responseData);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETUSERLEAVE_FAILURE });
  }
};

//Leave Status Search(User)

export const getStatus = (token, fromDate, toDate) => async (dispatch) => {
  dispatch({ type: types.GETSTATUS_REQUEST });

  // Validate dates
  if (!validateDate(fromDate) || !validateDate(toDate)) {
    console.log("Invalid date format. Dates must be in YYYY-MM-DD format.");
    dispatch({
      type: types.GETSTATUS_FAILURE,
      error: "Invalid date format.",
    });
    return;
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getLeaveStatus`,
      {
        params: {
          fromDate,
          toDate,
        },
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const data = response.data;
    dispatch({ type: types.GETSTATUS_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETSTATUS_FAILURE });
  }
};

//get Leave Request(Admin)

export const getLeaveRequest = (token) => async (dispatch) => {
  dispatch({ type: types.GETLEAVEREQUEST_REQUEST });
  console.log(token);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getAllLeaves`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responseData = response.data;
    dispatch({ type: types.GETLEAVEREQUEST_SUCCESS, payload: responseData });
    console.log(responseData);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETLEAVEREQUEST_FAILURE });
  }
};

// Leave Status(Admin)

export const updateStatus = (token, employeeID, status) => async (dispatch) => {
  dispatch({ type: types.UPDATESTATUS_REQUEST });

  //status is a string
  //  const statusString = typeof status === 'string' ? status : JSON.stringify(status);

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/updateLeaveStatus`,
      {
        employeeID,
        status,
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.UPDATESTATUS_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.UPDATESTATUS_FAILURE });
  }
};

//Upcoming Events(Admin/User)

export const getUpcomingEvent = (token) => async (dispatch) => {
  dispatch({ type: types.UPCOMINGEVENT_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/upcomingEvents`,
      {
        header: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.UPCOMINGEVENT_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.UPCOMINGEVENT_FAILURE });
  }
};

//Create Event (admin)

export const createEvents = (token, data) => async (dispatch) => {
  dispatch({ type: types.CREATEEVENTS_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/createEvent`,
      data,
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const responsedata = response.data;
    dispatch({ type: types.CREATEEVENTS_SUCCESS, payload: responsedata });
    console.log("Response Data:", responsedata);
  } catch (error) {
    console.log("Error:", error.response ? error.response.data : error.message);
    dispatch({ type: types.CREATEEVENTS_FAILURE, payload: error.message });
  }
};

// Employee Hike (Admin)

export const getAllHikes = (token) => async (dispatch) => {
  dispatch({ type: types.GETALLHIKES_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getAllHikes`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.GETALLHIKES_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETALLHIKES_FAILURE });
  }
};

//Create Employee Hike (User)

export const createHike = (token, data) => async (dispatch) => {
  dispatch({ type: types.CREATEHIKE_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/createHike`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseData = response.data;
    dispatch({ type: types.CREATEHIKE_SUCCESS, payload: responseData });
    console.log(responseData);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.CREATEHIKE_FAILURE });
  }
};

// GetAllHike employee (User)

export const getUserHike = (token) => async (dispatch) => {
  dispatch({ type: types.GETUSERHIKE_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getuserAppraisalRequests`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseData = response.data;
    dispatch({ type: types.GETUSERHIKE_SUCCESS, payload: responseData });
    console.log(responseData);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETUSERHIKE_FAILURE });
  }
};

//Resign

export const resign = () => async (dispatch) => {
  dispatch({ type: types.RESIGN_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/resign`
    );
    const data = response.data;
    dispatch({ type: types.RESIGN_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.RESIGN_FAILURE });
  }
};

// Leave Details (User Dashboard)

export const getLeaveDetails = (token) => async (dispatch) => {
  dispatch({ type: types.GETLEAVEDETAILS_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/userDashboard`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({ type: types.GETLEAVEDETAILS_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETLEAVEDETAILS_FAILURE });
  }
};

//Awards & Rewards(Admin)

export const CreateRewards = (token, data) => async (dispatch) => {
  dispatch({ type: types.CREATEREWARDS_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/createReward`,
      data,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const responsedata = response.data;
    dispatch({ type: types.CREATEREWARDS_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.CREATEREWARDS_FAILURE });
  }
};

//Get Awards & Rewards(Admin)

export const getAwardsAndRewards = (token) => async (dispatch) => {
  dispatch({ type: types.GETAWARDSANDREWARDS_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getAllRewards`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const data = response.data;
    dispatch({ type: types.GETAWARDSANDREWARDS_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETAWARDSANDREWARDS_FAILURE, error: error.message });
  }
};

// Get Awards & Rewards (User)

export const getReward = (token) => async (dispatch) => {
  dispatch({ type: types.GETREWARD_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getRewards`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const data = response.data;
    dispatch({ type: types.GETREWARD_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETREWARD_FAILURE, error: error.message });
  }
};

// Raise a Complaint(User)

export const postComplaint = (token, data) => async (dispatch) => {
  dispatch({ type: types.POSTCOMPLAINT_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/createComplaint`,
      data,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responsedata = response.data;
    dispatch({ type: types.POSTCOMPLAINT_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.POSTCOMPLAINT_FAILURE, erro: error.message });
  }
};

// Get All Comaplaint(User)

export const GetUserComplaint = (token) => async (dispatch) => {
  dispatch({ type: types.GETUSERCOMPLAINT_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getuserComplaints`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responsedata = response.data;
    dispatch({ type: types.GETUSERCOMPLAINT_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETUSERCOMPLAINT_FAILURE, erro: error.message });
  }
};

// Get Complaint (Admin)

export const GetComplaint = (token) => async (dispatch) => {
  dispatch({ type: types.GETCOMPLAINT_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getComplaint`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responsedata = response.data;
    dispatch({ type: types.GETCOMPLAINT_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETCOMPLAINT_FAILURE, erro: error.message });
  }
};

// Tickets Statistics (Admin)

export const GetStatistics = (token) => async (dispatch) => {
  dispatch({ type: types.GETSTATISTICS_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getTicketStatistics`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responsedata = response.data;
    dispatch({ type: types.GETSTATISTICS_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETSTATISTICS_FAILURE, erro: error.message });
  }
};

//Update Ticket Status(Admin)

export const GetUpdateStatus =
  (token, ticketId, status) => async (dispatch) => {
    dispatch({ type: types.GETUPDATESTATUS_REQUEST });

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/updateTicketStatus`,
        {
          ticketId,
          status,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      const responsedata = response.data;
      dispatch({ type: types.GETUPDATESTATUS_SUCCESS, payload: responsedata });
      console.log(responsedata);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.GETUPDATESTATUS_FAILURE, error: error.message });
    }
  };

// Get All Tickets (Admin)

export const GetTickets = (token) => async (dispatch) => {
  dispatch({ type: types.GETTICKETS_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getTicket`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responsedata = response.data;
    dispatch({ type: types.GETTICKETS_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETTICKETS_FAILURE, error: error.message });
  }
};

// Get All Tickets(User)

export const GetAllTickets = (token) => async (dispatch) => {
  dispatch({ type: types.GETALLTICKETS_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getTicket`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responsedata = response.data;
    dispatch({ type: types.GETALLTICKETS_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETALLTICKETS_FAILURE, error: error.message });
  }
};

// getUserTickets   (user)

export const GetUserTickets = (token) => async (dispatch) => {
  dispatch({ type: types.GETUSERTICKETS_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getUserTicket`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responsedata = response.data;
    dispatch({ type: types.GETUSERTICKETS_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETUSERTICKETS_FAILURE, error: error.message });
  }
};

// Create Tickets(User)

export const CreateTickets = (token, data) => async (dispatch) => {
  dispatch({ type: types.CREATETICKETS_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/createTicket`,
      data,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responsedata = response.data;
    dispatch({ type: types.CREATETICKETS_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.CREATETICKETS_FAILURE, error: error.message });
  }
};

// 2FA verification setup(User employeePage)

export const EmployeeSetup2FA = (token, qrCode) => async (dispatch) => {
  dispatch({ type: types.EMPLOYEESETUP2FA_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/setup2fa`,
      {}, // Empty object for the request body if there are no additional parameters
      {
        headers: {
          Authorization: `Bearer ${token}`, // Assuming the token should be prefixed with 'Bearer '
        },
      }
    );

    const responsedata = response.data.qrCode;
    dispatch({ type: types.EMPLOYEESETUP2FA_SUCCESS, payload: responsedata });
    console.log(responsedata);

    // Assuming the QR code URL is in responsedata.qrCodeUrl
  } catch (error) {
    console.error(error);
    dispatch({ type: types.EMPLOYEESETUP2FA_FAILURE, error: error.message });
  }
};

// Employee Profile Page (User)

export const GetEmpInfo = (token) => async (dispatch) => {
  dispatch({ type: types.GETEMPINFO_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/employeeProfile`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responsedata = response.data;
    dispatch({ type: types.GETEMPINFO_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETEMPINFO_FAILURE, error: error.message });
  }
};

//Upload Documents(Admin)

export const postUploadDocument = (token, id, data) => async (dispatch) => {
  dispatch({ type: types.POSTUPLOADDOCUMENT_REQUEST });

  const formData = new FormData();
  formData.append("employeeID", id);

  // Single file fields
  if (data.photo) formData.append("photo", data.photo);
  if (data.resume) formData.append("resume", data.resume);
  if (data.idProof) formData.append("idProof", data.idProof);
  if (data.addressProof) formData.append("addressProof", data.addressProof);
  if (data.panCard) formData.append("panCard", data.panCard);
  if (data.bankStatement) formData.append("bankStatement", data.bankStatement);

  // Multi-file fields
  if (data.educationalDocument && data.educationalDocument.length > 0) {
    data.educationalDocument.forEach((file) => {
      formData.append("educationalDocument", file);
    });
  }

  if (data.priorExperience && data.priorExperience.length > 0) {
    data.priorExperience.forEach((file) => {
      formData.append("priorExperience", file);
    });
  }

  if (data.salarySlip && data.salarySlip.length > 0) {
    data.salarySlip.forEach((file) => {
      formData.append("salarySlip", file);
    });
  }

  if (data.otherDocuments && data.otherDocuments.length > 0) {
    data.otherDocuments.forEach((file) => {
      formData.append("otherDocuments", file);
    });
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/upload`,
      formData,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responsedata = response.data;
    dispatch({ type: types.POSTUPLOADDOCUMENT_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.error("Error uploading documents:", error);
    dispatch({ type: types.POSTUPLOADDOCUMENT_FAILURE, error: error.message });
  }
};

// Create Referral(User)

export const UserCreateReferral = (token, data) => async (dispatch) => {
  dispatch({ type: types.USERCREATEREFERRAL_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/submitReferral`,
      data,
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const responsedata = response.data;
    dispatch({ type: types.USERCREATEREFERRAL_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.USERCREATEREFERRAL_FAILURE, error: error.message });
  }
};

// Get All Referral(Admin)

export const getAllReferral = (token) => async (dispatch) => {
  dispatch({ type: types.GETALLREFERRAL_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getReferrals`,

      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responsedata = response.data;
    dispatch({ type: types.GETALLREFERRAL_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETALLREFERRAL_FAILURE, error: error.message });
  }
};

//Post Reimbursement(User)

export const UserReimbursement = (token, formData) => async (dispatch) => {
  dispatch({ type: types.USERREIMBURSEMENT_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/addReimbursement`,
      formData,
      {
        headers: {
          Authorization: ` ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const responsedata = response.data;
    dispatch({ type: types.USERREIMBURSEMENT_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.USERREIMBURSEMENT_FAILURE, error: error.message });
  }
};

//Get Reimbursement(Admin)

export const getAllReimbursement = (token) => async (dispatch) => {
  dispatch({ type: types.GETALLREIMBURSEMENT_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getReimbursement`,

      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    const responsedata = response.data;
    dispatch({
      type: types.GETALLREIMBURSEMENT_SUCCESS,
      payload: responsedata,
    });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETALLREIMBURSEMENT_FAILURE, error: error.message });
  }
};

//Approved Reimbursement(Admin)

export const ApprovedReimbursement =
  (token, reimbursementId, status) => async (dispatch) => {
    dispatch({ type: types.APPROVEDREIMBURSEMENT_REQUEST });

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/reimbursements/${reimbursementId}/approveReimbursement`,
        { status }, // Sending the array of attendance IDs in the request body
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.APPROVEDREIMBURSEMENT_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.APPROVEDREIMBURSEMENT_FAILURE });
    }
  };

//Payslip(User)

export const GetPayslip = (token, month, year) => async (dispatch) => {
  dispatch({ type: types.GETPAYSLIP_REQUEST });
 
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/getPayslip`,
      {
        headers: {
          Authorization: `${token}`,
        },
        params:  {
          month: month,
          year: year,
        }
      }
    );
 
    const responsedata = response.data;
    dispatch({ type: types.GETPAYSLIP_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETPAYSLIP_FAILURE, error: error.message });
  }
};

//Create Payroll(Admin)
export const createPayroll = (token, data) => async (dispatch) => {
  dispatch({ type: types.CREATEPAYROLL_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/createPayroll`,
      data,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    const responseData = response.data;
    dispatch({ type: types.CREATEPAYROLL_SUCCESS, payload: data });
    console.log(responseData);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.CREATEPAYROLL_FAILURE });
  }
};


//Download Payroll(Admin)
export const DownloadPayroll = (token, payrollMonth) => async (dispatch) => {
  dispatch({ type: types.DOWNLOADPAYROLL_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/downloadPayroll?payrollMonth=2024-11`,
      {
        params: { payrollMonth },
        headers: {
          Authorization: `${token}`, 
        },
        responseType: "blob", // Handle file download as binary data (Blob)
      }
    );

    // Create a URL for the blob response
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Create a temporary link element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    // Set the download filename with payrollMonth in the name
    link.setAttribute("download", `payroll_${payrollMonth}.xlsx`);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    dispatch({ type: types.DOWNLOADPAYROLL_SUCCESS, payload: response.data });
    console.log("Payroll download successful:", response.data);
  } catch (error) {
    console.log("Error downloading payroll:", error);
    dispatch({ type: types.DOWNLOADPAYROLL_FAILURE, error: error.message });
  }
};

//Get All Payroll(Admin)
export const GetPayroll = (token) => async (dispatch) => {
  dispatch({ type: types.GETPAYROLL_REQUEST });
 
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/payrolls/last`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
 
    const responsedata = response.data;
    dispatch({ type: types.GETPAYROLL_SUCCESS, payload: responsedata });
    console.log(responsedata);
  } catch (error) {
    console.log(error);
    dispatch({ type: types.GETPAYROLL_FAILURE, error: error.message });
  }
};











export {};
