import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { ResetPassword, logout } from "../../Redux/AuthReducer/Action";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

const Settings = () => {
  const { colorMode } = useColorMode();
  const [employeeId, setEmployeeId] = useState("");
  const [enterOldPassword, setEnterOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const userEmployeeID = useSelector((state) => state.auth.user?.employeeID);
  const hashpassword = useSelector((state) => state.auth.hashedPasswords);
  const strongPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;

  const hasConsecutiveCharsRegex = /(.)\1{2,}/;

  const handleSubmit = () => {
    if (!employeeId || !enterOldPassword || !newPassword || !confirmNewPassword) {
      setErrorMessage("All fields are required.");
      return;
    }
  
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("New password and confirm password do not match.");
      return;
    }

    if (newPassword === enterOldPassword) {
      setErrorMessage("New password and old password do not match.");
      return;
    }

    if (hasConsecutiveCharsRegex.test(newPassword)) {
      return;
    }
  
    const hashedString = CryptoJS.SHA256(enterOldPassword).toString();
  
    if (hashpassword !== hashedString) {
      setErrorMessage("Wrong old password.");
      return;
    }
  
    setErrorMessage(""); // Clear any previous errors.
  
    dispatch(ResetPassword(token, toast, employeeId, enterOldPassword, newPassword, navigate))
     
  };
  
  const handleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleConfirmNewPasswordVisibility = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };
  const resetForm = () => {
    setErrorMessage("");
    setEmployeeId("");
    setEnterOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmNewPassword(false);
  };
  return (
    <Box
      mt="30px"
      ml="280px"
      background={colorMode === "dark" ? "dark" : "#FCF7D2"}
      borderWidth="1px"
      height={"95vh"}
    >
      <Box
        border="2px solid #E04141"
        borderRadius="md"
        p={2}
        mt={"70px"}
        ml={"250px"}
        mr={4}
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        width={"40%"}
        pt={"10px"}
        mb={4}
        pl={"17px"}
        bg={colorMode === "dark" ? "dark.700" : "#FAE8E1"}
      >
        <FormLabel
          fontSize="xl"
          mt={1}
          mb={4}
          pl={"120px"}
          color={colorMode === "dark" ? "white" : "black"}
        >
          Reset Password
        </FormLabel>
        <FormControl id="EmployeeID" mb={4}>
          <FormLabel>EmployeeID</FormLabel>
          <Select
            borderColor={"#E04141"}
            placeholder="Employee ID"
            value={employeeId}
            onChange={(e) => setEmployeeId(e.target.value)}
          >
            <option value={userEmployeeID}>{userEmployeeID}</option>
          </Select>
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Old Password</FormLabel>
          <InputGroup>
            <Input
              borderColor={"#E04141"}
              type={showOldPassword ? "text" : "password"}
              placeholder="Enter your old password"
              value={enterOldPassword}
              onChange={(e) => setEnterOldPassword(e.target.value)}
              maxLength={16}
            />
            <InputRightElement>
              <IconButton
                variant="ghost"
                icon={showOldPassword ? <ViewOffIcon /> : <ViewIcon />}
                onClick={handleOldPasswordVisibility}
                aria-label={showOldPassword ? "Hide password" : "Show password"}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>New Password</FormLabel>
          <InputGroup>
            <Input
              borderColor={"#E04141"}
              type={showNewPassword ? "text" : "password"}
              placeholder="Enter your new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              maxLength={16}
            />
            <InputRightElement>
              <IconButton
                variant="ghost"
                icon={showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                onClick={handleNewPasswordVisibility}
                aria-label={showNewPassword ? "Hide password" : "Show password"}
              />
            </InputRightElement>
          </InputGroup>
          {newPassword && (
            <Box mt={2}>
              <Text
                color={
                  strongPasswordRegex.test(newPassword)
                    ? "green.500"
                    : "red.500"
                }
              >
                Password must include 8-16 characters, uppercase, lowercase,
                number, and special character.
              </Text>
              {hasConsecutiveCharsRegex.test(newPassword) && (
                <Text color="red.500">
                  Password cannot contain any character repeated more than twice
                  consecutively
                </Text>
              )}
            </Box>
          )}
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Confirm New Password</FormLabel>
          <InputGroup>
            <Input
              borderColor={"#E04141"}
              type={showConfirmNewPassword ? "text" : "password"}
              placeholder="Confirm your new password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              maxLength={16}
            />
            <InputRightElement>
              <IconButton
                variant="ghost"
                icon={showConfirmNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                onClick={handleConfirmNewPasswordVisibility}
                aria-label={
                  showConfirmNewPassword ? "Hide password" : "Show password"
                }
              />
            </InputRightElement>
          </InputGroup>
          {newPassword !== confirmNewPassword && confirmNewPassword && (
            <Text color="red.500" mt={2}>
              Passwords do not match.
            </Text>
          )}
        </FormControl>
        {errorMessage && (
          <Text color="red.500" mt={2}>
            {errorMessage}
          </Text>
        )}
        <Button
          mr={3}
          onClick={handleSubmit}
          color={"#FECE01"}
          background={"#E04141"}
        >
          Submit
        </Button>
        <Button onClick={resetForm}>Cancel</Button>
      </Box>
    </Box>
  );
};
export default Settings;
